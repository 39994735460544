import React, { useState, useEffect } from 'react';
import { db } from '../index'; // Assurez-vous que l'instance 'db' est correctement exportée
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import './TeamsManager.css'; // Assurez-vous d'importer le fichier CSS

function TeamsManager() {
    const [teamName, setTeamName] = useState('');
    const [teams, setTeams] = useState([]);
    const [points, setPoints] = useState({});
    const [error, setError] = useState('');
    const [games, setGames] = useState([]);
    const [selectedGameState, setSelectedGameState] = useState({});
    const [epreuves, setEpreuves] = useState('');
    const [activeSection, setActiveSection] = useState('teams'); // Pour gérer la section active du menu
    const [menuOpen, setMenuOpen] = useState(false); // État pour le menu mobile

    // Charger les équipes et les parties à partir de Firestore
    const fetchTeams = async () => {
        const teamsCollection = collection(db, "team");
        const snapshot = await getDocs(teamsCollection);
        const teamsList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setTeams(teamsList);
    };

    const fetchGames = async () => {
        const gamesCollection = collection(db, "partie");
        const snapshot = await getDocs(gamesCollection);
        const gamesList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setGames(gamesList);
    };

    useEffect(() => {
        fetchTeams();
        fetchGames();
    }, []);

    // Ajouter une nouvelle équipe
    const handleAddTeam = async () => {
        if (teamName) {
            const existingTeam = teams.find(team => team.Nom === teamName);
            if (existingTeam) {
                setError('Une équipe avec ce nom existe déjà.');
                return;
            }

            await addDoc(collection(db, "team"), {
                Nom: teamName,
                Points: 0
            });
            setTeamName('');
            setError('');
            await fetchTeams();
        } else {
            setError('Le nom de l\'équipe ne peut pas être vide.');
        }
    };

    // Mettre à jour les points d'une équipe
// Mettre à jour les points d'une équipe
    const handleUpdatePoints = async (teamId) => {
        // Vérifiez si les points pour l'équipe existent
        const updatedPoints = points[teamId]; // Récupérer les points mis à jour pour l'équipe
        if (updatedPoints !== undefined) { // Assurez-vous que la valeur est définie
            const teamRef = doc(db, "team", teamId);
            await updateDoc(teamRef, {
                Points: updatedPoints // Mettez à jour les points dans Firestore
            });
            await fetchTeams(); // Récupérer à nouveau toutes les équipes pour mettre à jour l'interface utilisateur
        } else {
            console.error('Points non définis pour l\'équipe avec ID:', teamId);
        }
    };

    const handlePointsChange = (teamId, value) => {
        // Ne permettez que des valeurs non négatives
        if (value >= 0) {
            setPoints((prevPoints) => ({
                ...prevPoints,
                [teamId]: value // Mettez à jour l'état des points
            }));
        }
    };
    // Supprimer une équipe
    const handleDeleteTeam = async (id) => {
        const teamRef = doc(db, "team", id);
        await deleteDoc(teamRef);
        await fetchTeams();
    };


    // Mettre à jour l'état de la partie
    const handleUpdateGameState = async (gameId) => {
        const gameRef = doc(db, "partie", gameId);
        await updateDoc(gameRef, {
            etat: selectedGameState[gameId]
        });
        await fetchGames();
    };

    // Gérer le changement de l'état de la partie
    const handleGameStateChange = (gameId, value) => {
        setSelectedGameState((prevStates) => ({
            ...prevStates,
            [gameId]: value
        }));
    };

    // Mettre à jour l'épreuve en cours
    const handleUpdateEpreuve = async (gameId) => {
        const gameRef = doc(db, "partie", "partie");
        await updateDoc(gameRef, {
            epreuve: epreuves
        });
        await fetchGames();
    };

    return (
        <div className="teams-manager">
            {/* Menu latéral */}
            <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
                <h2>Menu</h2>
                <button className="menu-toggle" onClick={() => setMenuOpen(!menuOpen)}>
                    ☰
                </button>
                <ul>
                    <li onClick={() => setActiveSection('teams')} className={activeSection === 'teams' ? 'active' : ''}>Gérer les Équipes</li>
                    <li onClick={() => setActiveSection('games')} className={activeSection === 'games' ? 'active' : ''}>Gérer les Parties</li>
                    <li onClick={() => setActiveSection('epreuves')} className={activeSection === 'epreuves' ? 'active' : ''}>Gérer les Épreuves</li>
                </ul>
            </div>

            {/* Contenu principal */}
            <div className="content">
                {activeSection === 'teams' && (
                    <div className="section">
                        <h2>Gestion des équipes</h2>
                        <input
                            type="text"
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            placeholder="Nom de l'équipe"
                            className="team-input"
                        />
                        <button onClick={handleAddTeam} className="add-button">Ajouter l'équipe</button>
                        {error && <p className="error">{error}</p>}

                        <ul className="team-list">
                            {teams.map((team) => (
                                <li key={team.id} className="team-item">
                                    <span>{team.Nom} - {team.Points} points</span>
                                    <input
                                        type="number"
                                        value={points[team.id] !== undefined ? points[team.id] : team.Points}
                                        onChange={(e) => handlePointsChange(team.id, Number(e.target.value))}
                                        className="points-input"
                                    />
                                    <button onClick={() => handleUpdatePoints(team.id)} className="update-button">Mettre à jour</button>
                                    <button onClick={() => handleDeleteTeam(team.id)} className="delete-button">Supprimer</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {activeSection === 'games' && (
                    <div className="section">
                        <h2>Gestion des parties</h2>
                        <ul className="game-list">
                            {games.map((game) => (
                                <li key={game.id} className="game-item">
                                    <span>Partie {game.id}</span>
                                    <select
                                        value={selectedGameState[game.id] !== undefined ? selectedGameState[game.id] : game.etat}
                                        onChange={(e) => handleGameStateChange(game.id, Number(e.target.value))}
                                        className="game-state-select"
                                    >
                                        <option value={0}>Pas encore démarré</option>
                                        <option value={1}>En cours</option>
                                        <option value={2}>Terminé</option>
                                    </select>
                                    <button onClick={() => handleUpdateGameState(game.id)} className="update-state-button">Mettre à jour</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {activeSection === 'epreuves' && (
                    <div className="section">
                        <h2>Gestion des épreuves</h2>
                        <input
                            type="text"
                            value={epreuves}
                            onChange={(e) => setEpreuves(e.target.value)}
                            placeholder="Nouvelle épreuve"
                            className="epreuve-input"
                        />
                        <button onClick={() => handleUpdateEpreuve()} className="update-button">Mettre à jour l'épreuve</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TeamsManager;
