import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getFirestore,  } from "firebase/firestore";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import TeamManager from "./manager/TeamManager";
import { getStorage } from "firebase/storage"; // Importer Storage

const root = ReactDOM.createRoot(document.getElementById('root'));

const firebaseConfig = {
    apiKey: "AIzaSyAo4SVorWTaAjpn2mOXw_wvxUGVK2V9KgI",
    authDomain: "burgerquiz-972ec.firebaseapp.com",
    projectId: "burgerquiz-972ec",
    storageBucket: "burgerquiz-972ec.appspot.com",
    messagingSenderId: "933077768616",
    appId: "1:933077768616:web:d033f8c88367762a42e59d"
};



const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app); // Initialisation de Storage

export { db, storage };

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/team-crud",
        element: <TeamManager/>,
    },
]);

root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
