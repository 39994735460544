import React, { useEffect, useState } from 'react';
import { db } from './index'; // Assurez-vous que le chemin est correct
import { collection, getDocs, doc, onSnapshot } from "firebase/firestore";
import './App.css'; // Assurez-vous d'avoir le bon chemin vers votre fichier CSS

const App = () => {
    const [curtainsOpen, setCurtainsOpen] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);
    const [logoVisible, setLogoVisible] = useState(true); // Gérer la visibilité du logo/texte
    const [teams, setTeams] = useState([]);
    const [secondsLeft, setSecondsLeft] = useState(30);
    const [state, setState] = useState(0);
    const [epreuve, setEpreuve] = useState(''); // État pour stocker la valeur du champ "epreuve"

    // Fonction pour récupérer les équipes depuis Firestore
    const fetchTeams = async () => {
        try {
            const teamsCollection = collection(db, 'team');
            const teamSnapshot = await getDocs(teamsCollection);
            const teamList = teamSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setTeams(teamList);
        } catch (error) {
            console.error('Erreur lors de la récupération des équipes:', error);
        }
    };

    useEffect(() => {
        // Écouter les mises à jour en temps réel du document "partie"
        const gameStateDoc = doc(db, 'partie', 'partie');
        const unsubscribe = onSnapshot(gameStateDoc, (doc) => {
            if (doc.exists()) {
                const gameState = doc.data().etat;
                const newEpreuve = doc.data().epreuve; // Récupérer le champ "epreuve"

                setState(gameState);
                setEpreuve(newEpreuve); // Mettre à jour l'état "epreuve"

                if (gameState === 1) {
                    // Fermer logo/texte d'abord
                    setLogoVisible(false);

                    // Après 1 seconde, ouvrir les rideaux
                    setTimeout(() => {
                        setCurtainsOpen(true);
                        setTimeout(() => {
                            setContentVisible(true); // Afficher le contenu après l'ouverture des rideaux
                        }, 2000); // Délai pour synchroniser avec l'animation des rideaux
                    }, 1000); // Attente pour la disparition du logo/texte
                } else {
                    // Fermer les rideaux d'abord
                    setContentVisible(false);
                    setCurtainsOpen(false);

                    // Après 2 secondes (une fois que les rideaux sont fermés), afficher logo/texte
                    setTimeout(() => {
                        setLogoVisible(true);
                    }, 2000); // Délai pour attendre la fermeture des rideaux
                }
            }
        });

        // Récupérer les équipes au démarrage
        fetchTeams();

        // Actualiser les équipes toutes les 30 secondes
        const interval = setInterval(() => {
            fetchTeams();
            setSecondsLeft(30); // Réinitialiser le timer
        }, 30000); // 30 secondes

        // Décrémenter le timer chaque seconde
        const countdown = setInterval(() => {
            setSecondsLeft(prev => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        return () => {
            clearInterval(interval); // Nettoyer l'intervalle
            clearInterval(countdown); // Nettoyer le timer
            unsubscribe(); // Arrêter d'écouter les changements d'état en temps réel
        };
    }, []);

    return (
        <div className="App">

            {/* Conteneur Logo et texte, il disparaît/apparaît ensemble */}
            <div style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                zIndex: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                visibility: logoVisible ? 'visible' : 'hidden', // Contrôle de la visibilité
                opacity: logoVisible ? 1 : 0, // Animation douce via opacity
                transition: 'opacity 1s ease-in-out', // Transition douce pour apparaitre/disparaitre
            }}>
                <img src="AMIGOFinal.png" className={"logo-amigo"} alt=""/>
                <h1 className={"text"}>{state === 0 ? 'Le jeu va bientôt démarrer' : 'Le jeu est terminé, merci :) !'}</h1>
            </div>

            <div className="projector top-left"></div>
            <div className="projector top-right"></div>
            {/* Rideaux */}
            <div className={`curtain-left ${curtainsOpen ? 'open' : ''}`}></div>
            <div className={`curtain-right ${curtainsOpen ? 'open' : ''}`}></div>

            {/* Scène */}
            <div className={`stage ${contentVisible ? 'show' : ''}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="stage-content">
                    {/* Remplacer le titre par la valeur du champ "epreuve" */}
                    <h1 className="App-header">
                        <span className={"titre"}>{epreuve || 'burger de la mort'}</span>
                    </h1>
                </div>
                <div className="ranking-container">
                    <div className="ranking-columns">
                        {/* Afficher les équipes */}
                        {teams.sort((a, b) => b.Points - a.Points).map((team, index) => (
                            <div key={team.id} className="ranking-item">
                                <b>{team.Nom}</b><br />{team.Points} pts
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Timer en bas à droite */}
            <div className="timer">
                <p style={{ margin: 0, padding: 0, textAlign: 'right' }}> <i className="fa-solid fa-rotate"></i> Mise à jour dans : {secondsLeft}s</p>
                <p style={{ margin: 0, marginTop: 5, padding: 0, textAlign: 'right', opacity: 0.9 }}>
                    <i className="fa-regular fa-copyright"></i> <b>Développé par <i>WEILL Alban</i></b>
                </p>
            </div>

            {/* Panneau */}
            <div className={`panel ${contentVisible ? 'show' : ''}`}>
                <img src="https://i.ibb.co/r4bzswQ/Design-sans-titre-2.png" alt="Panneau" />
            </div>
        </div>
    );
};

export default App;
